/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../../../components/layout';

import Navbar from '../../../../components/navbar/navbar';
import MobileNavbar from '../../../../components/mobile-navbar/mobile-navbar';
import {
  DisplayText,
  LayoutUI,
  FollowBar,
  LeadText,
  Stack,
  TwoUp,
  Subheader
} from '../../../../ui-kit';
import * as styles from './ambassador-profile.module.scss';

const AmbassadorProfile = ({ location, data }) => {
  const {
    // metaData,
    name,
    tagLine,
    quote,
    featuredAmbassador,
    profileImage,
    shortBio,
    qa,
    images,
    socialMediaLinks,
    website
  } = data.contentfulPageIndividualAmbassador;

  const socialLinks = {
    Insta: '',
    Facebook: '',
    Twitter: '',
    LinkedIn: ''
  };

  // eslint-disable-next-line no-unused-expressions
  socialMediaLinks &&
    socialMediaLinks.map(({ platform, link }) => {
      const platformNormalized = platform[0];
      if (platformNormalized === 'Insta') {
        socialLinks.Insta = link;
      } else if (platformNormalized === 'Facebook') {
        socialLinks.Facebook = link;
      } else if (platformNormalized === 'Twitter') {
        socialLinks.Twitter = link;
      } else if (platformNormalized === 'LinkedIn') {
        socialLinks.LinkedIn = link;
      }
      return socialLinks;
    });

  return (
    <Layout>
      <Navbar location={location} />
      <MobileNavbar />
      <div className={styles.Container}>
        <LayoutUI narrow>
          <Stack vertical spacing="extraLoose">
            <Stack vertical spacing="extraLoose" alignment="center">
              {/* {' '} */}
              {/* Personal Info + Image + Bio */}
              <Stack alignment="center" vertical spacing="extraLoose">
                {/* Name + Location + Quote */}
                <div className={styles.AmbassadorName}>
                  <DisplayText size="small">{name}</DisplayText>
                </div>
                <Stack alignment="center" vertical spacing="comfortable">
                  {/* {' '} */}
                  {/*  Location + Quote */}
                  <div className={styles.LocationSubheader}>
                    <Subheader>{tagLine.tagLine}</Subheader>
                  </div>
                  <div className={styles.FavoriteQuote}>
                    <LeadText>{quote.quote}</LeadText>
                  </div>
                </Stack>
              </Stack>
              <FollowBar
                instagram={socialLinks.Insta}
                facebook={socialLinks.Facebook}
                twitter={socialLinks.Twitter}
                linkedin={socialLinks.LinkedIn}
              />
              <div
                className={classnames(
                  styles.MainAmbassadorImage,
                  !featuredAmbassador ? styles.NonFeaturedHeader : null
                )}
              >
                <img src={profileImage.file.url} alt={`Portrait of ${name}`} />
              </div>
              <p>{shortBio.shortBio}</p>
            </Stack>

            <Stack vertical>
              <div className={styles.AmbassadorQuestionsHeader}>
                <Subheader size="large">Q&amp;A</Subheader>
              </div>
              <div className={styles.AmbassadorQuestionsSection}>
                <Stack vertical spacing="loose">
                  {qa &&
                    qa.map(({ heading, text }, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`keys-${i}`}>
                        <ul>
                          <li>{heading}</li>
                          <li>{text.text}</li>
                        </ul>
                        {featuredAmbassador && qa.length / 2 === i + 1 ? (
                          <TwoUp>
                            {images &&
                              images.map(image => (
                                <div
                                  key={image.file.url}
                                  className={styles.FeaturedBodyImageContainer}
                                >
                                  <img
                                    src={image.file.url}
                                    alt={`Portrait of ${name}`}
                                  />
                                </div>
                              ))}
                          </TwoUp>
                        ) : null}
                      </div>
                    ))}
                </Stack>
              </div>
            </Stack>

            <hr />
            <div className={styles.Footer}>
              <FollowBar
                instagram={socialLinks.Insta}
                facebook={socialLinks.Facebook}
                twitter={socialLinks.Twitter}
                linkedin={socialLinks.LinkedIn}
                website={website}
              />
            </div>
          </Stack>
        </LayoutUI>
      </div>
    </Layout>
  );
};

AmbassadorProfile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
};

export default AmbassadorProfile;

export const AmbassadorProfileQuery = graphql`
  query AmbassadorProfileQuery($slug: String!) {
    contentfulPageIndividualAmbassador(slug: { eq: $slug }) {
      name
      profileImage {
        file {
          url
          fileName
        }
      }
      tagLine {
        tagLine
      }
      quote {
        quote
      }
      socialMediaLinks {
        platform
        link
      }
      website
      shortBio {
        shortBio
      }
      images {
        file {
          url
          fileName
        }
      }
      qa {
        heading
        text {
          text
        }
      }
      slug
      featuredAmbassador
    }
  }
`;
